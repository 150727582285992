<template>
  <div class="officer">
    <div class="txt">
      <div class="left">
        <span class="l1"></span>
        <span class="l2"></span>
      </div>
      <p class="conter">为传播知识而来</p>
      <div class="bottom">
        <p @click="$router.push('/enter')">点击进入</p>
        <img src="@/assets/radio.png" />
      </div>
    </div>
    <div class="home">
      <a
        target="_blank"
        class="RecordNo"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43019002001604"
        >湘公网安备 43019002001604号</a
      >
      <a target="_blank" class="subtitle" href="https://beian.miit.gov.cn"
        >湘ICP备 18024560号-1</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'Officer'
}
</script>

<style scoped lang="less">
.officer {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url("~@/assets/bg.jpg") no-repeat center / cover;
  margin-top: -81px;
  .header {
    z-index: 2;
    position: fixed;
    top: 0;
  }
  .txt {
    z-index: 3;
    position: absolute;
    height: 257px;
    left: 302px;
    top: 386px;
    .left {
      z-index: 4;
      position: relative;
      width: 81px;
      height: 20px;
      .l1 {
        z-index: 5;
        position: absolute;
        display: inline-block;
        width: 20px;
        height: 20px;
        background: #dddbd0;
        border-radius: 50%;
      }
      .l2 {
        z-index: 6;
        position: absolute;
        display: inline-block;
        width: 81px;
        height: 5px;
        background: #dddbd0;
        border-radius: 0 3px 3px 0;
        top: 7.5px;
        left: 1px;
      }
    }
    .conter {
      z-index: 7;
      width: 384px;
      height: 65px;
      font-size: 46px;
      font-family: STYuanti-SC-Regular, STYuanti-SC;
      font-weight: 400;
      color: #dddbd0;
      line-height: 65px;
      letter-spacing: 1px;
      margin-top: 28px;
    }
    .bottom {
      z-index: 8;
      position: absolute;
      width: 211px;
      height: 57px;
      background: #dddbd0;
      border-radius: 45px;
      bottom: 0;
      p {
        z-index: 9;
        position: absolute;
        display: inline;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 57px;
        margin-left: 30px;
      }
      img {
        z-index: 10;
        position: absolute;
        display: inline;
        width: 31px;
        height: 31px;
        top: 14px;
        right: 30px;
      }
    }
  }
  .home {
    z-index: 100;
    .RecordNo {
      z-index: 101;
      position: absolute;
      bottom: 27px;
      left: 302px;
      font-size: 14px;
      color: #fff;
      text-decoration: none;
    }
    .subtitle {
      z-index: 102;
      position: absolute;
      bottom: 27px;
      left: 538px;
      font-size: 14px;
      color: #fff;
      text-decoration: none;
    }
  }
}
</style>
